/* jshint-disable */

import React, { Component } from "react";
import { WEBURL, MODE } from "../../constant/comman";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import CustomLoader from "../../components/common/customLoader";
import { callLogin, callSubLogin } from "../../Graphs/Login/Login";
import { Modal, notification } from "antd";
import { getEnv } from "../../Graphs/Comman/getEnv";
import {
  createUserSelectedRole,
  getUserCorppassData,
  getUserData,
  getUserRolesData,
} from "../../Graphs/User/getUserData";
import * as QueryString from "query-string";
import Logo from "../../assets/images/six_logo.png";
// import firebase from 'firebase/app';
// production
// GA1.1.652633209.1626412934
// development
// GA1.1.2005979090.1626413063

const role = "vendor";
// const role = "admin";
// const role = "organization";
const dummyLogin = true;

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {
        email: "",
        password: "",
      },
      errors: {
        email: "",
        password: "",
      },
      isLoading: false,
      isCorppass: true,
      envData: null,
      selectedRole: undefined,
      selectedNricFin: undefined,
      selectedRolUserId: undefined,
      roleData: [],
    };
  }

  async componentDidMount() {
    try {
      const { location } = this.props;
      let query = QueryString.parse(location.search);
      console.log("!!!!!!!query!!!!!!!", query);

      // //check firebase config and analytics event
      // // production
      // // const firebaseConfig = {
      // // 	apiKey: 'AIzaSyAyCcZz50caAx6fZIlG1c_xxW5lo3bQNoM',
      // // 	authDomain: 'finequities-b1c84.firebaseapp.com',
      // // 	projectId: 'finequities-b1c84',
      // // 	storageBucket: 'finequities-b1c84.appspot.com',
      // // 	messagingSenderId: '636212954083',
      // // 	appId: '1:636212954083:web:37f7abd398ca85792c96d6',
      // // 	measurementId: 'G-QYGNZL7442',
      // // };

      // // development
      // const firebaseConfig = {
      // 	apiKey: 'AIzaSyA_ro9qcuB8qkK42fD-_1Q-5qR7NEpsj1E',
      // 	authDomain: 'finequities-90aab.firebaseapp.com',
      // 	projectId: 'finequities-90aab',
      // 	storageBucket: 'finequities-90aab.appspot.com',
      // 	messagingSenderId: '869763273635',
      // 	appId: '1:869763273635:web:f5aba267c4f38f85698016',
      // 	measurementId: 'G-4LK029ZK3N',
      // };
      // // Initialize Firebase
      // firebase.initializeApp(firebaseConfig);
      // await require('firebase/analytics');
      // let firebaseAnalytics = await firebase.analytics();
      // await firebaseAnalytics.logEvent(
      // 	'signup_test',
      // 	{
      // 		id: '1',
      // 		email: 'finquities@giggslab.com',
      // 		first_name: 'yash',
      // 		last_name: 'shah',
      // 		via: 'web',
      // 	},
      // 	{
      // 		global: true,
      // 	}
      // );

      if (query.userId) {
        await this.getUserData(query.userId, query.role);
      } else if (query.nricFin) {
        await this.getPersonDataFromCors(query.nricFin);
      } else if (query.error) {
        if (query.type === "login") {
          if (query.loginType === "corppass") {
            notification["error"]({
              message: "Login",
              description:
                typeof query.error === "string"
                  ? query.error
                  : "Something went wrong with linkup",
            });

            await this.callMockPassLogin();
            return;
          }

          await this.callMockPassLogin();
          return;
        }

        this.errorUserData();
      } else {
        await this.listGetEnvFunction();
      }
    } catch (e) {
      // console.log("!!!!!!!!!Error ", e);
      notification["error"]({
        message: "Login",
        description: typeof e === "string" ? e : "Something went wrong",
      });
    }
  }

  callMockPassLogin = async () => {
    try {
      this.setState({
        isLoading: true,
      });
      await this.listGetEnvFunction();
      this.setState({
        isLoading: true,
      });
      this.callAuthorizeData();
      return;
    } catch (e) {
      console.log("!!!!!!Error in callMockPassLogin", e);
    }
  };

  errorUserData = () => {
    try {
      const { history } = this.props;
      this.setState({
        isLoading: true,
      });
      notification["error"]({
        message: "Login",
        description: "You are not registered with us",
      });
      history.push(`${process.env.PUBLIC_URL}/login`);
    } catch (err) {
      notification["error"]({
        message: "Login",
        description: err,
      });
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  };

  getUserData = async (userId, role = "vendor") => {
    try {
      const { history, setLoginFlag, setUserData, setUserToken } = this.props;
      this.setState({
        isLoading: true,
      });

      let userData = await getUserData(userId, role);
      // let userData = await getUserData(
      //   "657b5426-01d7-4fdb-94c5-320c8b8b4f6a",
      //   "vendor"
      // );
      // For sub vendor
      // let userData = await getUserData(
      //   "a934838e-b5c4-439e-8283-d6119ac17702",
      //   "vendor"
      // );

      // let userData = await getUserData(
      //   "18c7089f-0d80-4374-97b6-e266ae722d5e",
      //   "charity"
      // );

      console.log("!!!!!userData return", userData);

      if (userData.status == 500) {
        throw userData.message;
      }

      notification["success"]({
        message: "Login",
        description: "Login Successfully",
      });

      await setUserToken(userData.authToken);
      await setUserData(userData.data);
      await setLoginFlag(true);

      // history.push(`${process.env.PUBLIC_URL}/organization-home`);

      if (role === "vendor") {
        history.push(`${process.env.PUBLIC_URL}/home`);
      } else {
        history.push(`${process.env.PUBLIC_URL}/organization-home`);
      }
    } catch (err) {
      notification["error"]({
        message: "Login",
        description: err,
      });
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  };

  callAuthorizeData = () => {
    const { envData } = this.state;

    // var authoriseUrl =
    //   envData.authApiUrl +
    //   "?client_id=" +
    //   envData.clientId +
    //   "&attributes=" +
    //   envData.attributes +
    //   "&purpose=demonstrating MyInfo APIs" +
    //   "&state=" +
    //   encodeURIComponent("123") +
    //   "&redirect_uri=" +
    //   envData.redirectUrl;
    var authoriseUrl =
      envData.authApiUrl +
      "?client_id=" +
      envData.clientId +
      "&attributes=" +
      envData.attributes +
      "&purpose=Register you to six" +
      "&state=" +
      encodeURIComponent("123") +
      "&redirect_uri=" +
      envData.redirectUrl;

    console.log("!!!envData", envData, authoriseUrl);
    window.location = authoriseUrl;
  };

  callAuthorizeLoginData = () => {
    window.location = `${WEBURL}user-login/corppass?role=${role}`;
  };

  listGetEnvFunction = async () => {
    try {
      this.setState({
        isLoading: true,
      });
      let envData = await getEnv();

      this.setState({
        envData: envData,
      });
    } catch (e) {
      throw e;
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  };

  //call Login method
  loginAuth = async () => {
    try {
      const { fields, errors } = this.state;
      const { history, setLoginFlag, setUserData, setUserToken } = this.props;

      let subErrors = { ...errors };

      if (fields["email"] == "") {
        subErrors["email"] = "Please enter a valid email";
        subErrors["password"] = "";

        this.setState({
          errors: subErrors,
        });
        return;
      }

      if (fields["password"] == "") {
        subErrors["password"] = "Please enter a valid password";
        subErrors["email"] = "";

        this.setState({
          errors: subErrors,
        });
        return;
      }

      subErrors["password"] = "";
      subErrors["email"] = "";
      this.setState({
        errors: subErrors,
        isLoading: true,
      });

      let loginData = await callLogin(fields["email"], fields["password"]);
      console.log("!!!!!!loginData", loginData.status);

      if (loginData.status == 500) {
        throw loginData.message;
      }

      if (role !== "admin") {
        throw "You are not authorized to login";
      }

      notification["success"]({
        message: "Login",
        description: "Login Successfully",
      });

      await setUserToken(loginData.authToken);
      await setUserData(loginData.data);
      await setLoginFlag(true);

      history.push(`${process.env.PUBLIC_URL}/dashboard`);
      window.location.reload();
    } catch (err) {
      console.log("!!!!!Error printed here in admin Login!!!!!!", err);
      try {
        // if (role === "vendor") {
        //   console.log("!!!!Check for the sub user login!!!!");
        //   await this.checkForSubUserLogin();
        // } else {
        //   notification["error"]({
        //     message: "Login",
        //     description: err,
        //   });
        // }
        notification["error"]({
          message: "Login",
          description: err,
        });
      } catch (err) {
        notification["error"]({
          message: "Login",
          description: err,
        });
      }
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  };

  checkForSubUserLogin = async () => {
    try {
      const { fields } = this.state;
      const { history, setLoginFlag, setUserData, setUserToken } = this.props;

      this.setState({
        isLoading: true,
      });

      let loginData = await callSubLogin(fields["email"], fields["password"]);
      console.log("!!!!!!loginData", loginData.status);

      if (loginData.status == 500) {
        // check for the sub user login
        console.log("!!!!Check for the sub user login!!!!");
        throw loginData.message;
      }

      notification["success"]({
        message: "Login",
        description: "Login Successfully",
      });

      await setUserToken(loginData.authToken);
      await setUserData(loginData.data);
      await setLoginFlag(true);

      if (loginData.data && loginData.data.role === "sub_vendor") {
        history.push(`${process.env.PUBLIC_URL}/voucher`);
      } else {
        history.push(`${process.env.PUBLIC_URL}/organization-beneficiaries`);
      }
    } catch (e) {
      throw e;
    }
  };

  //onchange of text input
  onChange = (text, name) => {
    const { fields } = this.state;
    let subFields = { ...fields };
    subFields[name] = text.target.value;
    this.setState({
      fields: subFields,
    });
  };

  getPersonDataFromCors = async (nricFin) => {
    try {
      const { history, setLoginFlag, setUserData, setUserToken } = this.props;
      this.setState({
        isLoading: true,
      });

      const userRoleData = await getUserRolesData(nricFin);
      const roleData = userRoleData.data;

      if (roleData.length > 1) {
        this.setState({
          selectedRole: undefined,
          roleData: roleData,
          selectedNricFin: nricFin,
        });

        return;
      }

      const userCorpsData = await getUserCorppassData(
        nricFin,
        role === "organization" ? "charity" : "vendor"
        // "charity"
      );

      console.log("!!!!From Corppass!!!!!!!", userCorpsData.data);

      if (userCorpsData.status == 500) {
        throw userCorpsData.message;
      }

      const userData = await getUserData(
        userCorpsData.data.userId,
        userCorpsData.data.role
      );
      // let userData = await getUserData(
      //   "657b5426-01d7-4fdb-94c5-320c8b8b4f6a",
      //   "vendor"
      // );
      // let userData = await getUserData(
      //   "18c7089f-0d80-4374-97b6-e266ae722d5e",
      //   "charity"
      // );

      // console.log("!!!!!userData return", userData);

      if (userData.status == 500) {
        throw userData.message;
      }

      notification["success"]({
        message: "Login",
        description: "Login Successfully",
      });

      await setUserToken(userData.authToken);
      await setUserData(userData.data);
      await setLoginFlag(true);

      // history.push(`${process.env.PUBLIC_URL}/organization-home`);

      if (userCorpsData.data.role === "vendor") {
        history.push(`${process.env.PUBLIC_URL}/home`);
      } else {
        history.push(`${process.env.PUBLIC_URL}/organization-home`);
      }
      window.location.reload();
    } catch (err) {
      notification["error"]({
        message: "Login",
        description: err,
      });
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  };

  loginUserWithChoosenRole = async () => {
    try {
      const { selectedRole, selectedNricFin } = this.state;
      const { history, setLoginFlag, setUserData, setUserToken } = this.props;

      this.setState({
        roleData: [],
        selectedRole: undefined,
        selectedNricFin: undefined,
        isLoading: true,
      });

      if (selectedRole && selectedNricFin) {
        const userCorpsData = await getUserCorppassData(
          selectedNricFin,
          selectedRole
        );
        console.log("!!!!From Corppass!!!!!!!", userCorpsData.data);

        if (userCorpsData.status == 500) {
          throw userCorpsData.message;
        }

        const userData = await getUserData(
          userCorpsData.data.userId,
          userCorpsData.data.role
        );
        // let userData = await getUserData(
        //   "657b5426-01d7-4fdb-94c5-320c8b8b4f6a",
        //   "vendor"
        // );
        // let userData = await getUserData(
        //   "18c7089f-0d80-4374-97b6-e266ae722d5e",
        //   "charity"
        // );

        // console.log("!!!!!userData return", userData);

        if (userData.status == 500) {
          throw userData.message;
        }

        // create user selected role
        await createUserSelectedRole(selectedNricFin, selectedRole);

        notification["success"]({
          message: "Login",
          description: "Login Successfully",
        });

        await setUserToken(userData.authToken);
        await setUserData(userData.data);
        await setLoginFlag(true);

        // history.push(`${process.env.PUBLIC_URL}/organization-home`);

        if (userCorpsData.data.role === "vendor") {
          history.push(`${process.env.PUBLIC_URL}/home`);
        } else {
          history.push(`${process.env.PUBLIC_URL}/organization-home`);
        }
        window.location.reload();

        return;
      }

      notification["error"]({
        message: "Login",
        description: "Please Login again",
      });
    } catch (err) {
      notification["error"]({
        message: "Login",
        description: err,
      });
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  };

  render() {
     const {
       errors,
       isLoading,
       isCorppass,
       selectedRolUserId,
       roleData,
       selectedRole,
     } = this.state;


    return (
      <div>
        {isLoading ? <CustomLoader /> : null}
        {roleData && roleData.length > 1 && (
          <Modal
            title="Choose Role"
            onCancel={() => {
              this.setState({
                roleData: [],
                selectedRole: undefined,
                selectedNricFin: undefined,
              });
            }}
            visible={roleData && roleData.length > 1}
            onOk={() => this.loginUserWithChoosenRole()}
          >
            <div style={{ marginBottom: "10px" }}>
              <span style={{ fontWeight: "bold", marginRight: "10px" }}>
                Selected Role:
              </span>
              <span>
                {selectedRole
                  ? String(
                      selectedRole === "charity" ? "Organization" : selectedRole
                    ).toLocaleUpperCase()
                  : "No Role Selected"}
              </span>
            </div>

            {roleData.map((roleItem) =>
              roleItem.role === "vendor" || roleItem.role === "charity" ? (
                <div
                  onClick={() =>
                    this.setState({
                      selectedRole: roleItem.role,
                    })
                  }
                  style={{
                    marginBottom: "5px",
                    cursor: "pointer",
                  }}
                >
                  <span>
                    {String(
                      roleItem.role === "charity"
                        ? "Organization"
                        : roleItem.role
                    ).toLocaleUpperCase()}
                  </span>
                </div>
              ) : null
            )}
          </Modal>
        )}

        <div className="page-wrapper">
          <div className="container-fluid p-0">
            {/* <!-- login page start--> */}
            <div className="authentication-main">
              <div className="row">
                <div className="col-md-12">
                  <div className="auth-innerright">
                    <div className="authentication-box">
                      <div className="text-center">
                        <div
                          style={{
                            textAlign: "center",
                            marginTop: "9px",
                            fontSize: "25px",
                            fontWeight: "bold",
                            color: "#4466f2",
                          }}
                        >
                          <img src={Logo} style={{ width: "35%" }} alt="" />
                        </div>
                      </div>
                      {isCorppass && !dummyLogin ? (
                        <div className="card mt-4">
                          <div className="card-body">
                            <div className="text-center">
                              <h4>CORPPASS LOGIN</h4>
                              <h6>You can login using CorppassId</h6>
                            </div>
                          </div>

                          <div
                            style={{
                              paddingLeft: "100px",
                              paddingRight: "100px",
                              marginBottom: "30px",
                            }}
                            className="form-group form-row mt-3 mb-30"
                          >
                            <button
                              className="btn btn-primary btn-block"
                              type="button"
                              onClick={() => this.callAuthorizeLoginData()}
                            >
                              Login
                            </button>
                          </div>
                          {MODE === "development" ? (
                            <div
                              style={{
                                paddingLeft: "100px",
                                paddingRight: "100px",
                                marginBottom: "30px",
                              }}
                              className="form-group form-row mt-3 mb-30"
                            >
                              <button
                                className="btn btn-primary btn-block"
                                type="button"
                                onClick={() => this.callMockPassLogin()}
                              >
                                Mockpass Login
                              </button>
                            </div>
                          ) : null}
                          {/* {MODE === "development" && role === "vendor" ? (
                            <div
                              style={{
                                paddingLeft: "100px",
                                paddingRight: "100px",
                                marginBottom: "30px",
                              }}
                              className="form-group form-row mt-3 mb-30"
                            >
                              <button
                                className="btn btn-primary btn-block"
                                type="button"
                                onClick={() =>
                                  this.setState({
                                    isCorppass: false,
                                  })
                                }
                              >
                                Sub Vendor Login
                              </button>
                            </div>
                          ) : null} */}
                        </div>
                      ) : isCorppass && dummyLogin ? (
                        <div className="card mt-4">
                          <div className="card-body">
                            <div className="text-center">
                              <h4>CORPPASS LOGIN</h4>
                              <h6>You can login using CorppassId</h6>
                            </div>
                          </div>

                          <div
                            style={{
                              paddingLeft: "100px",
                              paddingRight: "100px",
                            }}
                          >
                            {role === "organization" ? (
                              <div className="form-group form-row">
                                <label className="col-form-label">
                                  Select Organization
                                </label>
                                <select
                                  className="form-control"
                                  onChange={(event) => {
                                    this.setState({
                                      selectedRolUserId: event.target.value,
                                    });
                                  }}
                                  required
                                >
                                  <option value="">Select Organization</option>
                                  <option value="61c7b76c-96d5-4c6b-a073-2b925e18fbd0">
                                    Organization W ( T99LL1997E )
                                  </option>
                                  <option value="481c91d6-4f06-412a-b06a-c2241161c573">
                                    Organization X ( T05LL0608A )
                                  </option>
                                  <option value="7ef5af20-19a7-47d7-bc96-0fdd367f49c3">
                                    Organization Y ( 198102460H )
                                  </option>
                                  <option value="18c7089f-0d80-4374-97b6-e266ae722d5e">
                                    Organization Z ( 53234923K )
                                  </option>
                                </select>
                              </div>
                            ) : (
                              <div className="form-group form-row">
                                <label className="col-form-label">
                                  Select Vendor
                                </label>
                                <select
                                  className="form-control"
                                  onChange={(event) => {
                                    this.setState({
                                      selectedRolUserId: event.target.value,
                                    });
                                  }}
                                  required
                                >
                                  <option value="">Select Vendor</option>
                                  <option value="0319d9d8-43f8-4aac-bf30-a1d1d2a39dc2">
                                    Vendor B ( T05LL0608A )
                                  </option>
                                  <option value="657b5426-01d7-4fdb-94c5-320c8b8b4f6a">
                                    Vendor A ( T15LP8766B )
                                  </option>
                                  <option value="abe1cdb7-2972-4f88-a420-c1a8b3a2c1d7">
                                    Grab ( T99LL1997E )
                                  </option>
                                </select>
                              </div>
                            )}
                          </div>

                          <div
                            style={{
                              paddingLeft: "100px",
                              paddingRight: "100px",
                              marginBottom: "30px",
                            }}
                            className="form-group form-row mt-3 mb-30"
                          >
                            <button
                              className="btn btn-primary btn-block"
                              type="button"
                              onClick={() => {
                                if (selectedRolUserId) {
                                  this.getUserData(selectedRolUserId, role);
                                } else {
                                  notification["error"]({
                                    message: "Login",
                                    description: "Please Select Organization",
                                  });
                                }
                              }}
                            >
                              Login
                            </button>
                          </div>
                        </div>
                      ) : (
                        <div className="card mt-4">
                          <div className="card-body">
                            <div className="text-center">
                              <h4>LOGIN</h4>
                              <h6>Enter your Username and Password </h6>
                            </div>
                            <form
                              className="theme-form"
                              action={() => this.loginAuth()}
                            >
                              <div className="form-group">
                                <label className="col-form-label pt-0">
                                  Email
                                </label>
                                <input
                                  className="form-control"
                                  type="text"
                                  required
                                  onChange={(text) =>
                                    this.onChange(text, "email")
                                  }
                                />
                                <label
                                  className="col-form-label pt-0"
                                  style={{ color: "red" }}
                                >
                                  {errors["email"]}
                                </label>
                              </div>
                              <div className="form-group">
                                <label className="col-form-label">
                                  Password
                                </label>
                                <input
                                  className="form-control"
                                  type="password"
                                  required
                                  onChange={(text) =>
                                    this.onChange(text, "password")
                                  }
                                  onKeyDown={(e) => {
                                    if (e.key === "Enter") {
                                      this.loginAuth();
                                      // this.getUserData();
                                    }
                                  }}
                                />
                                <label
                                  className="col-form-label pt-0"
                                  style={{ color: "red" }}
                                >
                                  {errors["password"]}
                                </label>
                              </div>
                              <div className="form-group form-row mt-3 mb-0">
                                <button
                                  className="btn btn-primary btn-block"
                                  type="button"
                                  onClick={() => {
                                    this.loginAuth();
                                    // this.getUserData();
                                  }}
                                >
                                  Login
                                </button>
                              </div>
                              {/* <div className="login-divider"></div> */}
                            </form>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- login page end--> */}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  loginFlag: state.auth.loginFlag,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setLoginFlag: (flag) => {
      dispatch({
        type: "SET_LOGIN_FLAG",
        flag: flag,
      });
    },
    setUserData: (userData) => {
      dispatch({
        type: "SET_USER_DATA",
        userData: userData,
      });
    },
    setUserToken: (authToken) => {
      dispatch({
        type: "SET_USER_AUTHTOKEN",
        authToken: authToken,
      });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Login));
